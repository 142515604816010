export class MetaUtil {
    private static metaUtil: MetaUtil;

    // NOTE: Method to get the single instance of the class
    public static getInstance(): MetaUtil {
        if (!MetaUtil.metaUtil) {
            MetaUtil.metaUtil = new MetaUtil();
        }
        return MetaUtil.metaUtil;
    }

    getMetaTags(key: string) {
        switch (key) {
            case 'RESUME':
                return {
                    title: '‍🚀 Talenlio-standout - A place to showcase talents and uncover opportunities 🚀',
                    description: 'The leading AI-powered 100% paperless hiring solution 🚀 Walk in and campus drives are made hassle-free for employers, and universities.',
                    image: 'https://storage.googleapis.com/talenlio/orgs/growthschool-email-banner.png',
                    url: 'https://showcase.talenlio.com/fix-resume'
                }
            default:
                return {
                    title: '👩‍🚀 Talenlio- - A place to showcase talents and uncover opportunities 🚀',
                    description: 'The leading AI-powered 100% paperless hiring solution 🚀 Walk in and campus drives are made hassle-free for employers, and universities.',
                    image: 'https://uploads-ssl.webflow.com/62a7569eec80fe876c6582d8/649896d6be66cac867e35e75_meta%20image.png',
                    url: 'https://www.talenlio.com'
                };
        }
    }
}
