import {Component, Input, OnInit} from '@angular/core';
import {CompanyOutDto, CompanyService, JobPostOutDto} from "../../service/company.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ROUTE} from "../../../common/route-constants/route";
import {JobCardComponent} from "../../../common/component/job-card/job-card.component";
import {WORK_TYPE_DISPLAY} from "../../enum/company.model";
import {
    LibCardSkeletonComponent,
    SKELETON_LAYOUT_TYPE
} from "../../../library/skeleton/lib-card-skeleton/lib-card-skeleton.component";
import {NgTemplateOutlet} from "@angular/common";

@Component({
    selector: 'app-job',
    standalone: true,
    imports: [
        JobCardComponent,
        LibCardSkeletonComponent,
        NgTemplateOutlet
    ],
    templateUrl: './job.component.html',
    styleUrl: './job.component.scss'
})
export class JobComponent implements OnInit {
    @Input() companyUrl: string;
    defaultCompanyLogo = "assets/images/default-logo.png";
    jobPostOutDtos: JobPostOutDto[];
    companyOutDto: CompanyOutDto;
    isZeroState: boolean;
    isLoading = false;
    protected readonly WORK_TYPE_DISPLAY = WORK_TYPE_DISPLAY;
    protected readonly SKELETON_LAYOUT_TYPE = SKELETON_LAYOUT_TYPE;

    constructor(private companyService: CompanyService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.setJobPostOutDto();
    }

    navigateToViewDetails(jobPostOutDto: JobPostOutDto): void {
        this.router.navigate([ROUTE.JOBS, jobPostOutDto?.id], {
            relativeTo: this.activatedRoute
        });
    }

    private setJobPostOutDto(): void {
        this.isLoading = true;
        this.companyService.findByUrl(this.companyUrl).subscribe(publicApiCompanyOutDto => {
            this.jobPostOutDtos = publicApiCompanyOutDto?.jobPostOutDtos;
            this.isLoading = false;
            this.isZeroState = publicApiCompanyOutDto?.jobPostOutDtos?.length === 0;
        });
    }
}
