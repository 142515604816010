<ng-container [ngTemplateOutlet]="isLoading ? loaderRef : jobDataRef">
</ng-container>

<ng-template #loaderRef>
    <lib-progress-bar [isLoading]="isLoading"></lib-progress-bar>
</ng-template>

<ng-template #jobDataRef>
    <ng-container [ngTemplateOutlet]="isJobStatusPublished ? jobDetailRef : messageRef">
    </ng-container>
</ng-template>

<ng-template #messageRef>
    <tal-no-job-found [urlHandle]="urlHandle"></tal-no-job-found>
</ng-template>

<ng-template #jobDetailRef>
    <div class="detail-container overflow-auto bg-center bg-cover h-screen p-5"
         style="background-image: url('assets/resume/talenlio-background-image.png');">
        <div class="company-container p-2 m-auto">
            <div class="color-primary flex items-center gap-2 cursor-pointer" (click)="goToAllJobs()">
                <div class="ti ti-arrow-narrow-left"></div>
                <div class="cursor-pointer">See all jobs</div>
            </div>
            <div class="item-container flex gap-6 w-full mt-8">
                <div class="job-container h-max-content overflow-hidden rounded-2xl box-shadow pb-6">
                    <div class="banner-container h-36">
                        <img class="w-full h-full"
                             [src]="jobPostOutDto?.companyOutDto?.careerSiteOutDto?.coverPictureOutDto?.url || defaultCoverImage"
                             alt="">
                    </div>
                    @if (jobPostOutDto?.companyOutDto?.logoOutDto?.url) {
                        <div class="company-logo w-20 h-20 overflow-hidden m-auto box-shadow" style="margin-top: -30px">
                            <img class="w-full h-full" [src]="jobPostOutDto?.companyOutDto?.logoOutDto?.url" alt="">
                        </div>
                    }
                    @if (jobPostOutDto?.jobTitleOutDto?.title) {
                        <div
                                class="company-title text-3xl font-medium mt-20 font-jost text-center career-site-font-family">
                            {{ jobPostOutDto?.jobTitleOutDto?.title }}
                        </div>
                    }

                    <div class="job-detail-container overflow-hidden">
                        <div class="job-detail-card overflow-hidden">
                            <div class="location-section flex gap-5">
                                @if (jobPostOutDto?.companyOutDto?.websiteLink) {
                                    <div class="company">
                                        <div class="company-content flex gap-2 items-center career-site-font-family">
                                            <div class="company-icon ti ti-briefcase"></div>
                                            <div class="section-title career-site-font-family">Company</div>
                                        </div>
                                        <div class="website-link w-full truncate flex items-center hover:animate-pulse"
                                             (click)="openWebsite()">
                                            Visit website
                                            <span class="ti ti-arrow-up-right arrow-icon">
                                        </span>
                                        </div>
                                    </div>
                                }
                                @if (jobPostLocations?.length > 0) {
                                    <div class="company">
                                        <div class="company-content flex gap-2 items-center career-site-font-family">
                                            <div class="company-icon ti ti-map-pin"></div>
                                            <div class="section-title">Location</div>
                                        </div>
                                        <div class="company-type w-full truncate"
                                             [matTooltip]="jobPostLocations">
                                            {{ jobPostLocations }}
                                        </div>
                                    </div>
                                }
                                @if (jobPostOutDto?.jobLevelOutDto?.title) {
                                    <div class="company">
                                        <div class="company-content flex gap-2 items-center career-site-font-family">
                                            <div class="company-icon  ti ti-chart-bar"></div>
                                            <div class="section-title">Experience</div>
                                        </div>
                                        <div class="company-type w-full truncate">
                                            {{ jobPostOutDto?.jobLevelOutDto?.title }}
                                        </div>
                                    </div>
                                }
                                @if (jobPostOutDto?.jobTypeOutDto?.name) {
                                    <div class="company">
                                        <div class="company-content flex gap-2 items-center career-site-font-family">
                                            <div class="company-icon ti ti-briefcase-2"></div>
                                            <div class="section-title career-site-font-family">Job Type</div>
                                        </div>
                                        <div class="company-type w-full truncate">
                                            {{ jobPostOutDto?.jobTypeOutDto?.name }}
                                        </div>
                                    </div>
                                }
                                @if (jobPostOutDto?.workType) {
                                    <div class="company">
                                        <div class="company-content flex gap-2 items-center career-site-font-family">
                                            <div class="company-icon ti ti-briefcase-2"></div>
                                            <div class="section-title career-site-font-family">Work Type</div>
                                        </div>
                                        <div class="company-type w-full truncate">
                                            {{ WORK_TYPE_DISPLAY[jobPostOutDto?.workType] }}
                                        </div>
                                    </div>
                                }
                            </div>
                            @if (isVisibleNonZeroState()) {
                                @if (jobPostOutDto?.jobPostHasSkillOutDtos?.length > 0) {
                                    <div class="skill-section">
                                        <div class="career-site-font-family section-heading">
                                            Skill Required
                                        </div>
                                        <div class="flex flex-wrap">
                                            @for (jobPostHasSkillOutDto of jobPostOutDto?.jobPostHasSkillOutDtos; track jobPostHasSkillOutDto?.id) {
                                                <div class="group-list mr-2 mt-2">
                                                    <div
                                                            class="container-title">{{ jobPostHasSkillOutDto?.skillOutDto?.skill }}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                @if (jobPostOutDto?.description) {
                                    <div class="about-section">
                                        <div class="section-heading career-site-font-family">About the job</div>
                                        <div class="about-paragraph career-site-font-family"
                                             [innerHTML]="jobPostOutDto?.description">
                                        </div>
                                    </div>
                                }
                                @if (jobPostOutDto?.responsibilities) {
                                    <div class="about-section ">
                                        <div class="section-heading career-site-font-family">Responsibilities</div>
                                        <div class="about-paragraph career-site-font-family"
                                             [innerHTML]="jobPostOutDto?.responsibilities">
                                        </div>
                                    </div>
                                }
                                @if (jobPostOutDto?.minSalary || jobPostOutDto?.maxSalary) {
                                    <div class="about-section">
                                        <h1 class="section-heading career-site-font-family">Annual Salary</h1>
                                        <div class="flex justify-start items-center">
                                            @if (jobPostOutDto?.minSalary) {
                                                <div class="group-list mr-2 mt-2 flex">
                                                    <div class="container-title mr-2">
                                                        {{ getSalary(jobPostOutDto?.minSalary, jobPostOutDto?.currency) }}
                                                    </div>
                                                </div>
                                            }
                                            @if (jobPostOutDto?.maxSalary) {
                                                <span class="mt-2">-</span>
                                                <div class="group-list mr-2 mt-2 flex ml-2">
                                                    <div class="container-title mr-2">
                                                        {{ getSalary(jobPostOutDto?.maxSalary, jobPostOutDto?.currency) }}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                @if (jobPostOutDto?.jobPostHasDegreeOutDtos?.length > 0) {
                                    <div class="about-section">
                                        <div class="section-heading career-site-font-family">Preferred Degree</div>
                                        <div class="flex flex-wrap">
                                            @for (jobPostHasDegreeOutDto of jobPostOutDto?.jobPostHasDegreeOutDtos; track jobPostHasDegreeOutDto?.id) {
                                                <div class="group-list mr-2 mt-2">
                                                    <div
                                                            class="container-title">{{ jobPostHasDegreeOutDto?.degreeOutDto?.title }}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                @if (jobPostOutDto?.requirements) {
                                    <div class="about-section">
                                        <div class="section-heading career-site-font-family">Additional</div>
                                        <div class="about-paragraph career-site-font-family"
                                             [innerHTML]="jobPostOutDto?.requirements">
                                        </div>
                                    </div>
                                }
                            } @else {
                                <div class="p-4 w-full">
                                    <img class="m-auto" src="assets/images/astronaut-logo.png" alt="" srcset="">
                                    <div class="text-xl text-center mt-5 title">No Job detail are available</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div class="cta-container sticky top-0 h-max overflow-hidden rounded-2xl">
                    <div class="apply-container p-6">
                        <div class="logo h-20 w-20 overflow-hidden">
                            <img class="w-full h-full"
                                 [src]="jobPostOutDto?.companyOutDto?.logoOutDto?.url || defaultCompanyLogo" alt="">
                        </div>
                        <div class="title text-2xl mt-5 career-site-font-family">Apply for this Job</div>
                        <div class="sub-title career-site-font-family text-base mt-3">Please let company know that you
                            found
                            this
                            position on our job board,
                            as that is a
                            great way to support us, so we can keep posting cool jobs every day.
                        </div>
                        <div
                                class="apply-cta cursor-pointer title text-base mt-4 p-6 rounded-full text-center career-site-font-family"
                                (click)="applyJob()">
                            Apply now
                        </div>
                    </div>
                </div>
                <div class="apply-jobs bottom-2 fixed w-full">
                    <div
                            class="apply-cta w-11/12 ml-2 cursor-pointer title career-site-font-family text-base mt-2 p-6 rounded-full text-center"
                            (click)="applyJob()">
                        Apply now
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-container mt-10">
            <app-powered-by-footer></app-powered-by-footer>
        </div>
    </div>
</ng-template>
