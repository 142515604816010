export class ResumeHasLanguageCommonDto {
    rowIndex: number;
    language: string; // TODO: it should be name
    languageProficiency: LANGUAGE_PROFICIENCY;
    sectionTitle?: string;
    title: string;
    isActive: boolean;
}

export class ResumeHasLanguageOutDto extends ResumeHasLanguageCommonDto {
    id: string;
    createdAt: Date;
    lastModifiedAt: Date;
}

export enum LANGUAGE_PROFICIENCY {
    BEGINNER = "BEGINNER",
    NATIVE = "NATIVE",
    PROFESSIONAL = "PROFESSIONAL",
    ADVANCED = "ADVANCED",
    INTERMEDIATE = "INTERMEDIATE"
}

export enum LANGUAGE_PROFICIENCY_DISPLAY {
    BEGINNER = "Beginner",
    NATIVE = "Native",
    PROFESSIONAL = "Professional",
    ADVANCED = "Advanced",
    INTERMEDIATE = "Intermediate"
}
