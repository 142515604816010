import {Component, Inject, OnInit} from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {ROUTE} from "../../../../../common/route-constants/route";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {JobPostOutDto} from "../../../../service/company.service";

@Component({
  selector: 'tal-candidate-logged-in',
  standalone: true,
  imports: [
    MatDialogContent
  ],
  templateUrl: './candidate-logged-in.component.html',
  styleUrl: './candidate-logged-in.component.scss'
})
export class CandidateLoggedInComponent implements OnInit {

  jobPostTitle: string;
  jobPostOutDto: JobPostOutDto;
  placementDriveId: string;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: any,
              private dialogRef: MatDialogRef<any>) {
  }

  ngOnInit(): void {
    this.setJobPostTitle();
    this.setJobPostOutDto();
    this.setPlacementDriveId();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  navigateToLoginPage(): void {
    if (this.placementDriveId) {
      window.open(environment.standoutBaseAppUrl + ROUTE.SLASH + ROUTE.AUTH + ROUTE.SLASH + ROUTE.LOGIN + '?jobPostId=' + this.jobPostOutDto?.id + '&placementDriveId=' + this.placementDriveId , '_blank')
    } else {
      window.open(environment.standoutBaseAppUrl + ROUTE.SLASH + ROUTE.AUTH + ROUTE.SLASH + ROUTE.LOGIN + '?jobPostId=' + this.jobPostOutDto?.id , '_blank')
    }
  }

  navigateToSignUpPage(): void {
    if (this.placementDriveId) {
      window.open(environment.standoutBaseAppUrl + ROUTE.SLASH + ROUTE.AUTH + ROUTE.SLASH + ROUTE.CANDIDATE + ROUTE.SLASH + ROUTE.REGISTER + '?jobPostId=' + this.jobPostOutDto?.id + '&placementDriveId=' + this.placementDriveId, '_blank')
    } else {
      window.open(environment.standoutBaseAppUrl + ROUTE.SLASH + ROUTE.AUTH + ROUTE.SLASH + ROUTE.CANDIDATE + ROUTE.SLASH + ROUTE.REGISTER + '?jobPostId=' + this.jobPostOutDto?.id, '_blank')
    }
  }

  private setJobPostTitle(): void {
    this.jobPostTitle = this.dialogData?.jobPostOutDto?.jobTitleOutDto?.title;
  }

  private setJobPostOutDto(): void {
    this.jobPostOutDto = this.dialogData?.jobPostOutDto;
  }
  private setPlacementDriveId(): void {
    this.placementDriveId = this.dialogData?.placementDriveId;
  }
}
