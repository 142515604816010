export const environment = {
    name: 'dev',
    production: false,
    baseApiUrl: 'https://api.dev.talenlio.com/api',
    firebase: {
        apiKey: "AIzaSyB1AI4k7x6JrsDtzYgc1wTgGiomgAYcb_I",
        authDomain: "dev-and-staging-talenlio.firebaseapp.com",
        databaseURL: "https://dev-and-staging-talenlio-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "dev-and-staging-talenlio",
        storageBucket: "dev-and-staging-talenlio.appspot.com",
        messagingSenderId: "703883115589",
        appId: "1:703883115589:web:1ef42e2c010e3d907bf72c",
        measurementId: "G-5QGBL6L1Q9",
        vapidKey: "BGocAB3R5Oon-eVwXt2yH--lQsBG0uBSQ7UJFXyFyC4_kMjG-YTM1nr8hnh9jqj9j72hBDa0-VeqxfKiuJpsYSU"
    },
    edgeFirebase: {
        databaseURL: "https://dev-and-staging-talenlio-edge.europe-west1.firebasedatabase.app/",
    },
    featureFlagFirebase: {
        databaseURL: "https://dev-and-staging-talenlio-feature-flag.europe-west1.firebasedatabase.app/",
    },
    publicApiKey: '10260a23-0241-4171-b52d-6ece53cdff26',
    showcaseBaseAppUrl: 'https://showcase.dev.talenlio.com',
    standoutBaseAppUrl: 'https://standout.dev.talenlio.com',
    signUpBatchId: 'W8wFc31t',
    fixMyResumeBatchId: 'fix-my-resume',
};

