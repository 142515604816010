import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class MetaTagService {

    constructor(private titleService: Title,
                private metaService: Meta) {
    }

    removeTags(): void {
        this.metaService.removeTag('name="description"');
        this.metaService.removeTag('property="og:url"');
        this.metaService.removeTag('property="og:type"');
        this.metaService.removeTag('property="og:title"');
        this.metaService.removeTag('property="og:description"');
        this.metaService.removeTag('property="og:image"');
        this.metaService.removeTag('name="twitter:card"');
        this.metaService.removeTag('property="twitter:domain"');
        this.metaService.removeTag('property="twitter:url"');
        this.metaService.removeTag('name="twitter:title"');
        this.metaService.removeTag('name="twitter:description"');
        this.metaService.removeTag('name="twitter:image"');
    }

    addResumeTags(url: string): void {
        this.metaService.updateTag({name: 'description', content: 'It\'s time to shine with Talenlio'});
        this.metaService.updateTag({property: 'og:url', content: url});
        this.metaService.updateTag({property: 'og:type', content: 'website'});
        this.metaService.updateTag({property: 'og:title', content: 'Checkout my awesome resume'});
        this.metaService.updateTag({property: 'og:description', content: 'It\'s time to shine with Talenlio'});
        this.metaService.updateTag({
            property: 'og:image',
            content: 'https://uploads-ssl.webflow.com/62a7569eec80fe876c6582d8/64b7e24d827b3394df67a1f9_Talenlio.png'
        });
        this.metaService.updateTag({name: 'twitter:card', content: 'summary_large_image'});
        this.metaService.updateTag({property: 'twitter:domain', content: 'showcase.talenlio.com'});
        this.metaService.updateTag({property: 'twitter:url', content: url});
        this.metaService.updateTag({name: 'twitter:title', content: 'Checkout my awesome resume'});
        this.metaService.updateTag({name: 'twitter:description', content: 'It\'s time to shine with Talenlio'});
        this.metaService.updateTag({
            name: 'twitter:image',
            content: 'https://uploads-ssl.webflow.com/62a7569eec80fe876c6582d8/64b7e24d827b3394df67a1f9_Talenlio.png'
        });
    }

    setTitle(title: string) {
        this.titleService.setTitle(title);
    }

    setMeta(name: string, content: string) {
        this.metaService.addTag({name, content});
    }

    setDescription(description: string) {
        this.setMeta('description', description);
    }

    setKeywords(keywords: string[]) {
        this.setMeta('keywords', keywords.join(', '));
    }
}
