<div class="job-opening-container mt-5 p-4">
    @if (isLoading) {
        <ng-container *ngTemplateOutlet="skeletonLoaderRef"></ng-container>
    } @else {
        @if (!isZeroState) {
            @for (jobPostOutDto of jobPostOutDtos; track jobPostOutDto?.id) {
                <div (click)="navigateToViewDetails(jobPostOutDto)">
                    <tal-job-card [logo]="jobPostOutDto?.companyOutDto?.logoOutDto?.url || defaultCompanyLogo"
                                  [websiteLink]="jobPostOutDto?.companyOutDto?.websiteLink"
                                  [title]="jobPostOutDto?.jobTitleOutDto?.title"
                                  [companyName]="jobPostOutDto?.companyOutDto?.name"
                                  [location]="jobPostOutDto?.locationOutDto?.city"
                                  [workType]="WORK_TYPE_DISPLAY[jobPostOutDto?.workType]"
                                  [jobType]="jobPostOutDto?.jobTypeOutDto?.name"
                                  [level]="jobPostOutDto?.jobLevelOutDto?.title"
                                  [isViwJobSectionVisible]="false">
                    </tal-job-card>
                </div>
            }
        } @else {
            <div class="grid place-content-center text-center">
                <img class="ml-auto mr-auto" src="assets/images/astronaut-logo.png" alt="">
                <h1 class="text-2xl font-medium leading-6 mt-5">No Jobs Available!!!</h1>
            </div>
        }
    }
</div>


<ng-template #skeletonLoaderRef>
    @for (i of [1, 2, 3, 4, 5]; track i) {
        <lib-card-skeleton [layout]="SKELETON_LAYOUT_TYPE.VERTICAL">
        </lib-card-skeleton>
    }
</ng-template>
