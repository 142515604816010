import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {ROUTE} from './common/route-constants/route';
import {MetaTagService} from './service/meta-tag.service';
import {MetaUtil} from './common/util/meta.util';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, HttpClientModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'mexico';

    constructor(private meta: Meta,
                private titleService: Title,
                private router: Router,
                private metaTagService: MetaTagService) {
        // TODO: set default
        const resumeMeta = MetaUtil.getInstance().getMetaTags('default');
        this.addMetaTags(resumeMeta);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.updateMetaTags();
            }
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.metaTagService.removeTags();
    }

    private updateMetaTags(): void {
        const currentRoute = this.router.url;
        if (currentRoute.includes(ROUTE.SLASH + ROUTE.RESUME + ROUTE.SLASH)) {
            const resumeMeta = MetaUtil.getInstance().getMetaTags('RESUME');
            this.setMetaTags(resumeMeta);
        } else {
            const defaultMeta = MetaUtil.getInstance().getMetaTags('default');
            this.setMetaTags(defaultMeta);
        }
    }

    private setMetaTags(meta: any): void {
        this.titleService.setTitle(meta.title);
        this.meta.updateTag({property: 'og:title', content: meta.title});
        this.meta.updateTag({property: 'og:description', content: meta.description});
        this.meta.updateTag({property: 'og:image', content: meta.image});
        this.meta.updateTag({property: 'og:url', content: meta.url});
        this.meta.updateTag({name: 'og:title', content: meta.title});
        this.meta.updateTag({name: 'og:description', content: meta.description});
        this.meta.updateTag({name: 'og:image', content: meta.image});
        this.meta.updateTag({name: 'og:url', content: meta.url});
        this.meta.updateTag({name: 'twitter:title', content: meta.title});
        this.meta.updateTag({name: 'twitter:description', content: meta.description});
        this.meta.updateTag({name: 'twitter:image', content: meta.image});
    }

    private addMetaTags(meta: any): void {
        this.titleService.setTitle(meta.title);
        this.meta.addTag({property: 'og:title', content: meta.title});
        this.meta.addTag({property: 'og:description', content: meta.description});
        this.meta.addTag({property: 'og:image', content: meta.image});
        this.meta.addTag({property: 'og:url', content: meta.url});
        this.meta.addTag({name: 'og:title', content: meta.title});
        this.meta.addTag({name: 'og:description', content: meta.description});
        this.meta.addTag({name: 'og:image', content: meta.image});
        this.meta.addTag({name: 'og:url', content: meta.url});
        this.meta.addTag({name: 'twitter:title', content: meta.title});
        this.meta.updateTag({name: 'twitter:description', content: meta.description});
        this.meta.addTag({name: 'twitter:image', content: meta.image});
    }
}
