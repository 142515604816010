<ng-container>
    <div class="section-title-right flex flex-row items-center justify-start resume-section-title-bottom mt-6">
        @if (!isClassicType) {
            <i class="text-sm sm:text-2xl"
               [ngClass]="resumeIcon?.summary"
               [style.color]="resumeTheme?.iconColor">
            </i>
        }
        <div class="resume-section-title"
             [ngClass]="isClassicType ? 'pl-0 sm:pl-0' : 'pl-1 sm:pl-2' "
             [style.color]="resumeTheme?.primaryColor">
            {{ (sectionTitle || RESUME_SECTION_TYPE_DISPLAY?.SUMMARY) | titlecase }}
        </div>
    </div>
    <hr [style.border-color]="isClassicType ? classicLayoutTheme?.borderColor : resumeTheme.iconColor "
        class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
    <div class="flex items-center justify-start resume-section-content-between"
         [style.color]="resumeTheme?.primaryColor">
        <div class="resume-section-container-description"
             [ngStyle]="{'color': resumeTheme?.primaryColor}"
             [innerHTML]="resumeHasSummaryOutDto?.summary">
        </div>
    </div>
</ng-container>
