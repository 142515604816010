import {Component, Input} from '@angular/core';
import {NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {LAYOUT_TYPE} from "../../common/layout-selection.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";
import {LANGUAGE_PROFICIENCY_DISPLAY, ResumeHasLanguageOutDto} from "../../common/resume-section-model/language.model";

@Component({
  selector: 'app-r-language',
  standalone: true,
  imports: [
    NgStyle,
    NgTemplateOutlet,
    NgClass,
    TitleCasePipe
  ],
  templateUrl: './r-language.component.html',
  styleUrl: './r-language.component.scss'
})
export class RLanguageComponent {
  @Input()
  resumeTheme: ResumeThemeModel;
  @Input()
  resumeHasLanguageOutDtos: ResumeHasLanguageOutDto[];
  @Input()
  layoutType: LAYOUT_TYPE;
  @Input()
  sectionTitle: string;
  @Input()
  isClassicType: boolean = false;
  classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
  LAYOUT_TYPE = LAYOUT_TYPE;
  protected readonly LANGUAGE_PROFICIENCY_DISPLAY = LANGUAGE_PROFICIENCY_DISPLAY;
  protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
}
