import {Component, Input} from '@angular/core';
import {NgClass, NgStyle, TitleCasePipe} from "@angular/common";
import {ResumeIconModel, ResumeIconUtil} from "../../common/resume-icon.model";
import {ResumeHasSummaryOutDto} from "../../common/resume-section-model/summary.model";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {LAYOUT_TYPE} from "../../common/layout-selection.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";

@Component({
    selector: 'app-r-summary',
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        TitleCasePipe
    ],
    templateUrl: './r-summary.component.html',
    styleUrl: './r-summary.component.scss'
})
export class RSummaryComponent {
    @Input()
    resumeTheme?: ResumeThemeModel;
    @Input()
    resumeIcon: ResumeIconUtil = ResumeIconModel.getIconForResume();
    @Input()
    resumeHasSummaryOutDto: ResumeHasSummaryOutDto;
    @Input()
    layoutType: LAYOUT_TYPE;
    @Input()
    isClassicType: boolean = false;
    @Input()
    sectionTitle: string;
    classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
    protected readonly LAYOUT_TYPE = LAYOUT_TYPE;
    protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
}
